<template>
  <ion-content>

    <ion-row class="ion-padding">
      <ion-col size="12" style="text-align:center;padding:5% 0 0;">
        <ion-img :src="require('@/assets/images/color-logo.png')" style="width:180px;margin:0 auto;" />
      </ion-col>
    </ion-row>
    
    <ion-row v-if="formState.step===1" class="form ion-text-center" id="recaptcha-container">
      <ion-col size="12">
        <h4>Enter your phone number</h4>
        <p style="font-size:13px;padding:0 0 10px">We will text a verification code to your phone number</p>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
        <ion-input inputmode="tel" type="text" v-model="formState.phone" placeholder="(000) 000-0000" @input="formatPhoneNumber($event)" />
      </ion-col>

      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton id="sign-in-button" :text="'Next'" :color="'#00777E'" :loading="formState.isLoading" @click="initRecaptcha"/>
        <span style="display:block;padding:2vh 0 0;font-size:13px;color:#00777E" @click="openBusinessRegistrationModal">ARE YOU A BUSINESS?</span>
      </ion-col>
    </ion-row>

    <ion-row v-else-if="formState.step===2" class="form ion-text-center">
      <ion-col size="12">
        <h4>Verification</h4>
        <p style="font-size:13px;padding:0 0 10px">You will receive a six digit verification code that is time-limited. Please enter the code below.</p>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
        <ion-input inputmode="tel" type="number" v-model="formState.verificationCode" placeholder="000000" />
      </ion-col>

      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Validate'" :color="'#00777E'" :loading="formState.isLoading" @click="validatePhoneNumber"/>
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router'

/* Firebase */
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
const fb = require('@/firebase/init')

/* Ionic */
import { IonContent, IonRow, IonCol, IonInput, IonText, IonImg } from '@ionic/vue';
import { modalController  } from '@ionic/vue';

/* Mixins */
import { popoverService } from "@/classes/popovers"
import {keyboardResize, openToast} from '@/mixins/helpers'

/* Components */
import RoundButton from '@/components/buttons/Solid'
import Icon from '@/components/widgets/Icon.vue'

export default  {
  name: 'Signup',

  components: {
    IonContent, IonRow, IonCol, IonInput, IonText, IonImg,
    RoundButton, Icon,
  },

  setup() {
    const router = useRouter()

    let formState = reactive({
      isLoading: false,
      step:1,
      phone:null,
      verificationCode:null,
      appVerifier:null,
    });

    onMounted(() => {
      const user = fb.authenticate.currentUser
      if (user) {
        closeModal()
      } else {
        keyboardResize()
      }
    })

    const closeModal = () => {
      modalController.dismiss();
    };

    const openBusinessRegistrationModal = () => {
      closeModal()
      popoverService.triggerBusinessSignupInfo()
    }

    const formatPhoneNumber = async (e) => {
      const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      formState.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }

    const initRecaptcha = async () => {
      formState.isLoading = true

      if (formState.phone.length===14) {
        const auth = getAuth()

        console.log("Submitting recaptcha")

        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
          'size': 'invisible',
          'callback': (response) => {       
          },
          'expired-callback': () => {
          }
        }, auth)
      } else {
        openToast('Please provide a valid phone number/', 'warning')
      }

      formState.appVerifier = window.recaptchaVerifier
      submitPhoneNumber()

    };  

    const submitPhoneNumber = async () => {
      const auth = getAuth()
      const number = ('+1'+formState.phone).replace(/[^\d\+]/g,"")

      signInWithPhoneNumber(auth, number, formState.appVerifier).then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log(confirmationResult)
        if (confirmationResult) {
          formState.step=2
        }
      }).catch((error) => {
        openToast('There was a problem. Please try again.', 'warning')
      });

      formState.isLoading = false
    }
   
    const validatePhoneNumber = async () => {
      if (formState.verificationCode.length===6) {
        formState.isLoading = true
        window.confirmationResult.confirm(formState.verificationCode).then((result) => {
          // User signed in successfully.
          const user = result.user;
          fb.db.collection('Users').doc(user.uid).set({
          'dates': {'created':new Date(),'last_login':new Date()},
          'profile': {
            'uid':user.uid,
            'phone_number':user.phoneNumber,
            'role': 'consumer',
            'active': true,
            'profile_photo': null,
          }
        }, {merge:true})
          .then( () => {
            formState.isLoading = false
            router.push('/')
            closeModal()
          }).catch( () => {
            formState.isLoading = false
            console.log('ERROR adding to global')
          })
          // ...
        }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
        });
        formState.isLoading = false
      } else {
        openToast('Invalid verification code.', 'warning')
      }
    }

    return { 
      formState,

      closeModal,
      openBusinessRegistrationModal,
      initRecaptcha,
      formatPhoneNumber,
      validatePhoneNumber
    };
  },

}

</script>

<style scoped>
ion-content {
  background:#ffffff;
}

.form ion-col {
  padding:1.5vh 10vw
}

>>>.grecaptcha-badge { display: none!important; }

</style>