<template>
  <ion-page>
    <ion-content>   
      <ion-row class="ion-text-center">
        <ion-col>
          <ion-img :src="require('@/assets/images/white-logo.png')" style="width:270px;margin:10vh auto 0;" />
        </ion-col>
      </ion-row>

      <ion-row class="actions">
        <ion-col size="12">
          <RoundButton :text="'Login'"  @click="openLoginModal" />
        </ion-col>
        <ion-col size="12">
          <span style="font-size:15px;color:#FFFFFF">OR</span>
        </ion-col>
        <ion-col size="12">
          <RoundButton :text="'Sign Up'" @click="openSignUpModal" />
        </ion-col>
        <ion-col size="12">
          <span style="font-size:15px;color:#FFFFFF" @click="openBusinessRegistrationModal">ARE YOU A BUSINESS?</span>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
/* Vue/Vuex */
import { useRouter } from 'vue-router'
import { onMounted } from 'vue';

/* Firebase */
import 'firebase/auth'
const fb = require('@/firebase/init')

/* Ionic */
import { IonPage, IonContent, IonImg, IonRow, IonCol } from '@ionic/vue';
import { modalController  } from '@ionic/vue'; 

/* Components */
import RoundButton from '@/components/buttons/Solid';

import { popoverService } from "@/classes/popovers"

export default  {
  name: 'SplashScreen',

  components: { 
    IonContent, IonPage, IonImg, IonRow, IonCol, 
    RoundButton 
  },

  setup() {
    const router = useRouter()

    onMounted(() => {
      const user = fb.authenticate.currentUser
      if (user) {
        router.push('/')
      }
    })

    const openLoginModal = async () => {
      const modal = await modalController
        .create({
          component: require('@/components/modals/Authentication/PhoneAuthentication').default,
          cssClass: 'login-modal three-quarter-modal',
        })
      return modal.present();
    };

    const openSignUpModal = async () => {
      const modal = await modalController
        .create({
          component: require('@/components/modals/Authentication/PhoneAuthentication').default,
          cssClass: 'signin-modal three-quarter-modal',
        })
      return modal.present();
    };

    const openBusinessRegistrationModal = async () => {
      popoverService.triggerBusinessSignupInfo()
    };
  
    return {
      openLoginModal,
      openSignUpModal,
      openBusinessRegistrationModal
    };
  },

}
</script>

<style scoped>

ion-content {
  --background: #000 url('../assets/images/background-01.png') no-repeat center center / cover;
}

.actions {
  width:100%;
  padding:0 20vw;
  position:absolute;
  bottom:10vh;
  text-align:center;
}

.actions ion-col {
  padding:2vh 0;
}

ion-button {
  font-weight:900 !important;
}

</style>